import { graphql } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { useGlobalState } from "../state/globalState";

export default function Thankyou() {
  const { t } = useTranslation(["thank_you"]);
  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "dark",
      hidden: true,
    });
  }, []);

  return (
    <div
      className="w-full min-h-screen text-white bg-cover rounded-xl"
      style={{
        backgroundImage:
          "url(https://images.unsplash.com/photo-1534058315178-ae2061737165?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2166&q=80)",
      }}
    >
      <div style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}>
        <div className="flex flex-col min-h-screen py-16 container-lg">
          <h1 className="mb-4 text-5xl">{t("thank_you:title")}</h1>
          <p className="text-lg leading-8">
            {t("thank_you:paragraph1")}
            <br />
            <br />
            {t("thank_you:paragraph2")}
          </p>
          <div className="mt-8">
            <Link
              to="/"
              className="inline-flex items-center px-6 py-3 space-x-4 font-medium text-black bg-white hover:shadow rounded-xl"
            >
              <svg
                className="w-5 h-5"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.4714 2.19526C8.72702 2.45088 8.73167 2.86243 8.48535 3.12371L8.4714 3.13807L4.2762 7.33333H13.3333C13.7015 7.33333 14 7.63181 14 8C14 8.36195 13.7116 8.65653 13.352 8.66641L13.3333 8.66667H4.2762L8.4714 12.8619C8.72702 13.1175 8.73167 13.5291 8.48535 13.7904L8.4714 13.8047C8.21579 14.0604 7.80424 14.065 7.54296 13.8187L7.5286 13.8047L2.19526 8.4714C1.93965 8.21579 1.935 7.80424 2.18132 7.54296L2.19526 7.5286L7.5286 2.19526C7.78895 1.93491 8.21106 1.93491 8.4714 2.19526Z"
                  fill="#64748B"
                />
              </svg>
              <span>{t("thank_you:cta")}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
